.hovedwrapper {
    display: flex;
    .sidebar {
        background-color: $black;
        height: calc(100vh - 80px);
        min-width: rem-calc(260);
        padding: rem-calc(0 20);
        .menu {
            margin-top: rem-calc(50);
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: grid;
                grid-template-columns: 1fr;
                grid-row-gap: rem-calc(10);
                li {
                    a {
                        align-items: center;
                        height: rem-calc(50);
                        display: flex;
                        width: 100%;
                        background-color: transparent;
                        border-radius: rem-calc(12);
                        padding: rem-calc(0 16);
                        color: $beige;
                        transition: all 0.2s ease;
                        svg {
                            margin-right: rem-calc(12);
                        }
                        span {
                            font-weight: 600;
                        }
                        &:hover {
                            cursor: pointer;
                            text-decoration: none;
                            background-color: transparentize($color: white, $amount: 0.9);
                            transition: all 0.2s ease;
                        }
                        &.active {
                            background-color: transparentize($color: white, $amount: 0.9);
                        }
                    }
                }
            }
        }
    }
    .content {
        background-color: $black;
        width: 100%;
        height: 100%;
        position: relative;
        .inner-content {
            padding: rem-calc(50);
            width: 100%;
            height: calc(100vh - 80px);
            background-color: $beige;
            border-top-left-radius: rem-calc(12);
            .overlay {
                display: none;
            }
            .menu-btn {
                display: none;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .hovedwrapper {
        display: flex;
        .sidebar {
            background-color: $black;
            height: calc(100vh - 70px);
            min-width: rem-calc(260);
            padding: rem-calc(0 20);
            position: absolute;
            z-index: 95;
            transform: translateX(-100%);
            transition: all 0.2s ease;
            &.mob-open {
                transform: translateX(0%);
                transition: all 0.2s ease;
            }
            .menu {
                margin-top: rem-calc(50);
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-row-gap: rem-calc(10);
                    li {
                        a {
                            align-items: center;
                            height: rem-calc(50);
                            display: flex;
                            width: 100%;
                            background-color: transparent;
                            border-radius: rem-calc(12);
                            padding: rem-calc(0 16);
                            color: $beige;
                            transition: all 0.2s ease;
                            svg {
                                margin-right: rem-calc(12);
                            }
                            span {
                                font-weight: 600;
                            }
                            &:hover {
                                cursor: pointer;
                                text-decoration: none;
                                background-color: transparentize($color: white, $amount: 0.9);
                                transition: all 0.2s ease;
                            }
                            &.active {
                                background-color: transparentize($color: white, $amount: 0.9);
                            }
                        }
                    }
                }
            }
        }
        .content {
            background-color: $black;
            width: 100%;
            height: 100%;
            position: relative;
            .inner-content {
                padding: rem-calc(20);
                width: 100%;
                height: calc(100vh - 70px);
                background-color: $beige;
                border-top-left-radius: 0;
                .overlay {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 93;
                    background-color: transparentize($color: $black, $amount: 0.25);
                    opacity: 0;
                    transition: all 0.2s ease;
                    pointer-events: none;
                    &.open {
                        opacity: 1;
                        transition: all 0.2s ease;
                        pointer-events: all;
                    }
                }
                .menu-btn {
                    display: flex;
                }
                .support-tab {
                    h2 {
                        margin: 0;
                        font-size: rem-calc(26);
                        line-height: rem-calc(34);
                        color: $text;
                        font-family: $regular;
                        font-weight: lighter;
                        text-align: left;
                        padding-bottom: rem-calc(15);
                    }
                    p {
                        font-size: rem-calc(16);
                    }
                }
            }
        }
    }
}