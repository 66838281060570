.faq {
    padding: rem-calc(80 0);
    position: relative;
    .inner-faq {
        h2 {
            font-family: $medium;
            font-size: rem-calc(48);
            line-height: rem-calc(58);
            font-weight: lighter;
            text-align: center;
            margin: 0;
            padding-bottom: rem-calc(25);
        }
        p {
            font-family: $regular;
            font-weight: lighter;
            color: $gray;
            font-size: rem-calc(20);
            line-height: rem-calc(28);
            padding-bottom: rem-calc(15);
            max-width: rem-calc(532);
            margin: 0px auto;
            text-align: center;
        }
        h3 {
            font-family: $medium;
            font-size: rem-calc(26);
            line-height: rem-calc(36);
            font-weight: lighter;
            text-align: center;
            margin: 0;
            padding-bottom: rem-calc(15);
            padding-top: rem-calc(35);
        }
        .accordion-wrapper {
            max-width: rem-calc(630);
            margin: 0px auto rem-calc(15);
            h4 {
                font-family: $medium;
                font-size: rem-calc(16);
                line-height: rem-calc(27);
                font-weight: lighter;
                text-align: center;
            }
            .MuiAccordion-rounded {
                &:before {
                    display: none !important;
                }
            }
        }

            .inner-single-faq {
                padding: rem-calc(0 9);
                p {
                    font-family: $regular;
                    font-weight: lighter;
                    color: $gray;
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                    padding-bottom: rem-calc(0);
                    max-width: 100%;
                    margin: 0;
                    text-align: left;
                }
            }
        
    }
}

@media screen and (max-width: $mobile) {
    .faq {
        padding: rem-calc(45 0);
        position: relative;
        .inner-faq {
            h2 {
                font-family: $medium;
                font-size: rem-calc(32);
                line-height: rem-calc(42);
                font-weight: lighter;
                text-align: center;
                margin: 0;
                padding-bottom: rem-calc(15);
            }
            p {
                font-family: $regular;
                font-weight: lighter;
                color: $gray;
                font-size: rem-calc(18);
                line-height: rem-calc(26);
                padding-bottom: rem-calc(15);
                max-width: rem-calc(532);
                margin: 0px auto;
                text-align: center;
            }
            h3 {
                font-family: $medium;
                font-size: rem-calc(20);
                line-height: rem-calc(28);
                font-weight: lighter;
                text-align: center;
                margin: 0;
                padding-bottom: rem-calc(10);
                padding-top: rem-calc(25);
            }
            .single-faq {
                box-shadow: $bs-light;
                border-radius: rem-calc(12);
                max-width: rem-calc(630);
                margin: 0px auto rem-calc(15);
                background-color: $beige;
                h4 {
                    display: flex;
                    align-items: center;
                    min-height: rem-calc(54);
                    padding: rem-calc(0 25);
                    font-family: $medium;
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                    font-weight: lighter;
                    text-align: center;
                    margin: 0;
                    padding-bottom: rem-calc(0);
                    justify-content: space-between;
                    &:hover {
                        cursor: pointer;
                    }
                }
                .inner-single-faq {
                    padding: rem-calc(0 25 25);
                    p {
                        font-family: $regular;
                        font-weight: lighter;
                        color: $gray;
                        font-size: rem-calc(16);
                        line-height: rem-calc(24);
                        padding-bottom: rem-calc(0);
                        max-width: 100%;
                        margin: 0;
                        text-align: left;
                    }
                }
            }
        }
    }
}