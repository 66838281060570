.header-bar {
    background-color: $black !important;
    z-index: 99;
    .inner-header {
        min-height: rem-calc(80);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
            svg {
                width: rem-calc(65);
                height: rem-calc(65);
            }
        }
        .wpml {
            display: none;
            align-items: center;
            height: 100%;
            img {
                width: rem-calc(24);
                height: rem-calc(24);
            }
            .avatar-wrapper {
                display: flex;
                align-items: center;
                border-left: rem-calc(1) solid #454545;
                margin-left: rem-calc(20);
                padding-left: rem-calc(20);
                p {
                    margin-left: rem-calc(10);
                    font-size: rem-calc(15);
                    max-width: rem-calc(250);
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .header-bar {
        background-color: $black !important;
        z-index: 99;
        .inner-header {
            min-height: rem-calc(70);
            display: flex;
            align-items: center;
            justify-content: space-between;
            h3 {
                display: none;
            }
            .logo {
                svg {
                    width: rem-calc(45);
                    height: rem-calc(45);
                }
            }
            .wpml {
                display: none;
                align-items: center;
                button {
                    height: rem-calc(35);
                    width: rem-calc(115);
                    padding: rem-calc(8 15);
                    font-size: rem-calc(12);
                    svg {
                        width: rem-calc(19);
                        height: rem-calc(19);
                    }
                }
                img {
                    width: rem-calc(22);
                    height: rem-calc(22);
                }
                .avatar-wrapper {
                    display: none;
                    align-items: center;
                    border-left: rem-calc(1) solid #454545;
                    margin-left: rem-calc(8);
                    padding-left: rem-calc(8);
                    .MuiAvatar-root {
                        display: none;
                    }
                    p {
                        margin-left: rem-calc(8);
                        font-size: rem-calc(12);
                    }
                }
            }
        }
    }
}