.gdpr {
    padding: rem-calc(80 0);
    position: relative;
    .inner {
        border-radius: rem-calc(12);
        box-shadow: $bs-light;
        .gdpr-header {
            padding: rem-calc(75 70 130);
            background-color: $beige;
            position: relative;
            h1 {
                font-size: rem-calc(60);
                font-family: $bold;
                padding-bottom: rem-calc(30);
            }
            p {
                font-family: $regular;
                margin: 0;
            }
            img {
                &.curve {
                    max-width: 100%;
                    position: absolute;
                    bottom: rem-calc(-5);
                    left: 0;
                }
            }
        }
        .gdpr-body {
            padding: rem-calc(30 70 70);
            position: relative;
            display: grid;
            grid-template-columns: 5fr 2fr;
            column-gap: rem-calc(75);
            .left {
                h3 {
                    font-family: $medium;
                    font-size: rem-calc(20);
                    padding-bottom: rem-calc(20);
                }
                p {
                    margin: 0;
                    padding-bottom: rem-calc(30);
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                    color: $gray;
                }
                button {
                    margin-top: rem-calc(25);
                }
            }
            .right {
                .box {
                    padding: rem-calc(25 25 30);
                    border-radius: rem-calc(4);
                    border: rem-calc(1) solid #DDDDDD;
                    h3 {
                        font-size: rem-calc(18);
                        line-height: rem-calc(26);
                        font-family: $medium;
                        padding-bottom: rem-calc(15);
                    }
                    p {
                        margin: 0;
                        padding-bottom: rem-calc(15);
                        font-size: rem-calc(16);
                        line-height: rem-calc(24);
                        color: $gray;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .gdpr {
        padding: rem-calc(45 0);
        position: relative;
        .inner {
            border-radius: rem-calc(12);
            box-shadow: $bs-light;
            .gdpr-header {
                padding: rem-calc(35 35 40);
                background-color: $beige;
                position: relative;
                h1 {
                    font-size: rem-calc(32);
                    font-family: $bold;
                    padding-bottom: rem-calc(15);
                }
                p {
                    font-family: $regular;
                    margin: 0;
                    font-size: rem-calc(12);
                }
                img {
                    &.curve {
                        max-width: 100%;
                        position: absolute;
                        bottom: rem-calc(-5);
                        left: 0;
                    }
                }
            }
            .gdpr-body {
                padding: rem-calc(25 25 35);
                position: relative;
                display: grid;
                grid-template-columns: 1fr;
                column-gap: rem-calc(75);
                .left {
                    h3 {
                        font-family: $medium;
                        font-size: rem-calc(18);
                        line-height: rem-calc(26);
                        padding-bottom: rem-calc(10);
                    }
                    p {
                        margin: 0;
                        padding-bottom: rem-calc(20);
                        font-size: rem-calc(16);
                        line-height: rem-calc(24);
                        color: $gray;
                    }
                    button {
                        margin-top: rem-calc(10);
                        margin-bottom: rem-calc(35);
                    }
                }
                .right {
                    .box {
                        padding: rem-calc(20 20 25);
                        border-radius: rem-calc(4);
                        border: rem-calc(1) solid #DDDDDD;
                        h3 {
                            font-size: rem-calc(18);
                            line-height: rem-calc(26);
                            font-family: $medium;
                            padding-bottom: rem-calc(15);
                        }
                        p {
                            margin: 0;
                            padding-bottom: rem-calc(15);
                            font-size: rem-calc(16);
                            line-height: rem-calc(24);
                            color: $gray;
                        }
                    }
                }
            }
        }
    }
}