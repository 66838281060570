$rem-base: 16px;

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base-value: $rem-base)  {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);
  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }
  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

$red: #DD0A01;
$beige: #FAEFE6;
$black: #2A2D2E;
$gray: #788185;
$text: #2C3748;

$bold: 'JakartaB', sans-serif;
$regular: 'JakartaR', sans-serif;
$medium: 'JakartaM', sans-serif;

$bs: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
$bs-light: 0px 3px 6px rgba(0,0,0, 0.16);

$mobile: rem-calc(1024);

// Start styling 

.upload-img-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: rem-calc(10);
    grid-column-gap: rem-calc(10);
    img {
        width: 100%;
        height: 100%;
        border-radius: rem-calc(4);
        object-fit: cover;
        border: rem-calc(1) solid #9E9E9E;
    }
}

#video-chat {
    width: 100%;
    height: calc(100vh - 80px);
    overflow: hidden;
    background: $black; 

    .meeting-logo {
        svg {
            position: fixed;
            right: rem-calc(10);
            top: rem-calc(15);
            width: rem-calc(55);
            height: auto;
            opacity: 0.2;
            pointer-events: none;
            z-index: 99;
        }
    }

    #local-media-container {
        height: 100%;
        margin: 0px auto;
        width: clamp(95%, 95%, 85vw);
        position: relative;
        display: flex;
        align-items: center;
    }

    // Video containers
    video-player-container {
        width: 100%;
        height: 100%;
        position: relative;
        background: #000;
        border-radius: rem-calc(12);
        overflow: hidden;

        video-player {
            width: 100%;
            height: 100%;
            position: relative;

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    #videoholder1 {
        width: 100%;
        height: 100%;
        min-height: 300px;
    }

    #videoholder2 {
        position: absolute;
        right: rem-calc(20);
        bottom: rem-calc(100);
        width: rem-calc(240);
        height: rem-calc(180);
        z-index: 10;
        border: 2px solid #fff;
    }

    .patient-info {
        width: rem-calc(400);
        min-width: rem-calc(400);
        max-width: rem-calc(400);
        display: none;
        opacity: 0;
        pointer-events: none;
        height: 90%;
        padding: rem-calc(20 25);
        background: white;
        margin-right: rem-calc(25);
        border-radius: rem-calc(12);
        overflow-y: auto;
    }

    .meeting-controls {
        position: fixed;
        bottom: 0;
        width: 100vw;
        background: #393C3D;
        height: rem-calc(90);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// Mobile styles
@media screen and (max-width: $mobile) {
    #video-chat {
        height: calc(100vh - 70px);

        #local-media-container {
            flex-flow: column;
            justify-content: space-evenly;
        }

        #videoholder1 {
            width: 100%;
            height: 45%;
        }

        #videoholder2 {
            width: 100%;
            height: 45%;
            position: relative;
            right: unset;
            bottom: unset;
        }

        .meeting-controls {
            height: rem-calc(80);
        }

        .patient-info {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            height: calc(100vh - 80px);
            z-index: 99;
            bottom: 0;
            position: fixed;
            margin-right: 0;
        }
    }
}

#videoholder1, #videoholder2 {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 300px;
  background: #000;
  border-radius: 12px;
  overflow: hidden;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
}

#videoholder2 {
  position: absolute;
  right: rem-calc(20);
  bottom: rem-calc(100);
  width: rem-calc(240);
  height: rem-calc(180);
  z-index: 10;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  #videoholder1, #videoholder2 {
    min-height: 200px;
  }
  
  #videoholder1 video, #videoholder2 video {
    object-fit: cover;
  }
}

#videoholder1::after, #videoholder2::after {
  display: none;
}

#videoholder1 {
  width: 100%;
  height: 100%;
  min-height: 300px;
}

#videoholder2 {
  position: absolute;
  right: rem-calc(20);
  bottom: rem-calc(100);
  width: rem-calc(240);
  height: rem-calc(180);
  z-index: 10;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}

@media screen and (max-width: 768px) {
  #videoholder1, #videoholder2 {
    min-height: 200px;
  }
}