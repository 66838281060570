@font-face {
    font-family: 'JakartaB';
    src: local('Jakarta'), url('../fonts/PlusJakartaDisplay-Bold.woff') format('woff');
}
@font-face {
    font-family: 'JakartaR';
    src: local('Jakarta'), url('../fonts/PlusJakartaDisplay-Regular.woff') format('woff');
}
@font-face {
    font-family: 'JakartaM';
    src: local('Jakarta'), url('../fonts/PlusJakartaDisplay-Medium.woff') format('woff');
}

html,
body {
    min-height: 100%;
    height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    font-family: 'JakartaR', sans-serif, serif;
    position: relative;
}

h1, h2, h3, h4, h5, h6, p, a, span {
    font-weight: lighter;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

a {
    text-decoration: none;
    color: $red;
    &:hover {
        text-decoration: underline;
    }
}

.container {
    max-width: rem-calc(1230);
    margin: 0px auto;
    width: 100%;
    padding: rem-calc(0 40);
}

.MuiButton-root.MuiButton-contained {
    font-family: 'JakartaM', sans-serif, serif;
    &:hover {
        background-color: $red;
        text-decoration: none;
    }
}

@media screen and (max-width: $mobile) {
    .container {
        max-width: 100%;
        margin: 0px auto;
        width: calc(100vw - 40px);
        padding: rem-calc(0);
    }   
}

/** RESET MAILCHIMP STYLES **/

#mc_embed_signup .mc-field-group input {
    display: unset;
    width: unset;
    padding: rem-calc(17 14);
    text-indent: unset;
}

#mc_embed_signup input {
    border: unset;
    -webkit-border-radius: unset;
    -moz-border-radius: unset;
    border-radius: unset;
}