.income {
    h2 {
        margin: 0;
        font-size: rem-calc(32);
        line-height: rem-calc(43);
        color: $text;
        font-family: $regular;
        font-weight: lighter;
        text-align: left;
        padding-bottom: rem-calc(50);
    }
    .income-cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: rem-calc(32);
        .card {
            background-color: $black;
            border-radius: rem-calc(12);

            padding: rem-calc(32);
            h2 {
                font-weight: 900;
                font-size: rem-calc(32);
                line-height: rem-calc(42);
                color: $beige;
                margin-bottom: rem-calc(15);
                padding: rem-calc(0);
            }
            p {
                margin: rem-calc(0);
                padding: rem-calc(0);
                color: $gray;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .income {
        h2 {
            margin: 0;
            font-size: rem-calc(26);
            line-height: rem-calc(34);
            color: $text;
            font-family: $regular;
            font-weight: lighter;
            text-align: left;
            padding-bottom: rem-calc(25);
        }
        .income-cards {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: rem-calc(32);
            grid-row-gap: rem-calc(15);
            .card {
                background-color: $black;
                border-radius: rem-calc(12);
                padding: rem-calc(18);
                h2 {
                    font-weight: 900;
                    font-size: rem-calc(26);
                    line-height: rem-calc(34);
                    color: $beige;
                    margin-bottom: rem-calc(5);
                    padding: rem-calc(0);
                }
                p {
                    margin: rem-calc(0);
                    padding: rem-calc(0);
                    color: $gray;
                }
            }
        }
    }
}