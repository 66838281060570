footer {
    margin-top: rem-calc(140);
    padding-bottom: rem-calc(30);
    .inner-footer {
        border-top: rem-calc(1) #dedede solid;
        svg {
            width: rem-calc(55);
            height: rem-calc(55);
            display: block;
            margin: 30px auto 30px auto;
        }
        p {
            text-align: center;
            font-family: $regular;
            font-weight: lighter;
            color: $gray;
            font-size: rem-calc(14);
            line-height: rem-calc(24);
            margin: 0px auto;
            padding-bottom: rem-calc(20);
        }
    }
}

@media screen and (max-width: $mobile) {
    footer {
        margin-top: rem-calc(80);
        padding-bottom: rem-calc(15);
        .inner-footer {
            border-top: rem-calc(1) #dedede solid;
            svg {
                width: rem-calc(55);
                height: rem-calc(55);
                display: block;
                margin: 30px auto 30px auto;
            }
            p {
                text-align: center;
                font-family: $regular;
                font-weight: lighter;
                color: $gray;
                font-size: rem-calc(14);
                line-height: rem-calc(24);
                margin: 0px auto;
                padding-bottom: rem-calc(15);
            }
        }
    }
}