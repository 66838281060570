.hero {
    background-color: $beige;
    min-height: rem-calc(500);
    padding: rem-calc(126 0 136);
    position: relative;
    img {
        &.curve {
            max-width: 100%;
            position: absolute;
            bottom: rem-calc(-5);
        }
    }
    .inner-hero {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: rem-calc(30);
        align-items: center;
        .left {
            h1 {
                margin: 0;
                font-size: rem-calc(60);
                line-height: rem-calc(72);
                color: $text;
                font-family: $bold;
                padding-bottom: rem-calc(20);
                font-weight: lighter;
                span {
                    color: $red;
                }
            }
            p {
                &.lead {
                    margin: 0;
                    color: $gray;
                    font-size: rem-calc(20);
                    line-height: rem-calc(28);
                    padding-bottom: rem-calc(35);
                    font-family: $regular;
                }
            }
            .MuiButton-root {
                border-radius: rem-calc(25);
                padding: rem-calc(12 25);
                font-family: $medium;
                letter-spacing: rem-calc(0.5);
                font-size: rem-calc(14);
                background-color: $red;
            }
            .button-wrapper-hero {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: space-between;
                label {
                    display: flex;
                    flex-direction: column;
                    span {
                        margin-bottom: rem-calc(10);
                        display: inline-block;
                        color: $text;
                        font-family: $bold;
                    }
                }
            }
        }
        .right {
            img {
                width: rem-calc(250);
                object-fit: contain;
                margin: 0px auto;
                display: block;
            }
            svg {
                width: rem-calc(560);
                max-width: 100%;
                height: auto;
                object-fit: contain;
            }
        }
    }
}

.kaboom {
    margin-top: rem-calc(25);
    border-radius: rem-calc(25);
    padding: rem-calc(16 40);
    font-family: $medium;
    letter-spacing: rem-calc(0.5);
    font-size: rem-calc(14);
    background-color: $red;
    border-radius: rem-calc(25) !important;
    text-transform: uppercase;
    color: white;
    &:hover {
        cursor: pointer;
    }
}

#mc_embed_signup_scroll {
    label {
        padding-bottom: rem-calc(6);
        display: block;
    }
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px #fbf8fa inset !important;
        -webkit-text-fill-color: $text !important;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    input[type=text],
    input[type=email],
    input[type=tel],
    input[type=number] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        border-radius: rem-calc(12);
        background: #f5f8fa;
        border: rem-calc(2) solid #eaeaea;
        outline: none;
        width: 100%;
        color: $text;
        font-size: rem-calc(16);
        line-height: rem-calc(1);
        padding: rem-calc(0 26);
        transition: all 0.2s ease;
        height: rem-calc(55);
        margin-bottom: rem-calc(15);
        &::placeholder {
            color: darkgray;
            line-height: 1;
            font-size: rem-calc(16);
        }
    }
}

@media screen and (max-width: $mobile) {
    .hero {
        background-color: $beige;
        min-height: rem-calc(350);
        padding: rem-calc(45 0 55);
        position: relative;
        img {
            &.curve {
                max-width: 100%;
                position: absolute;
                bottom: rem-calc(-5);
            }
        }
        .inner-hero {
            display: grid;
            grid-template-columns: 1fr;
            column-gap: rem-calc(0);
            align-items: center;
            .left {
                h1 {
                    margin: 0;
                    font-size: rem-calc(36);
                    line-height: rem-calc(46);
                    color: $text;
                    text-align: center;
                    font-family: $bold;
                    padding-bottom: rem-calc(15);
                    font-weight: lighter;
                    span {
                        color: $red;
                    }
                }
                p {
                    &.lead {
                        margin: 0;
                        color: $gray;
                        font-size: rem-calc(18);
                        line-height: rem-calc(26);
                        padding-bottom: rem-calc(25);
                        font-family: $regular;
                        text-align: center;
                    }
                }
                .MuiButton-root {
                    border-radius: rem-calc(25);
                    padding: rem-calc(15 20);
                    font-family: $medium;
                    letter-spacing: rem-calc(0.5);
                    font-size: rem-calc(13);
                    background-color: $red;
                }
                button {
                    display: flex;
                    margin: 0px auto rem-calc(15);
                    max-width: rem-calc(250);
                    width: 100%;
                }
                .button-wrapper-hero {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    justify-content: center;
                    label {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        span {
                            margin-bottom: rem-calc(10);
                            display: inline-block;
                            color: $text;
                            font-family: $bold;
                            text-align: center;
                        }
                    }
                }
            }
            .right {
                img {
                    width: rem-calc(150s);
                    object-fit: contain;
                    margin: rem-calc(25) auto 0;
                    display: block;
                }
                svg {
                    max-width: 100%;
                    padding: rem-calc(25);
                    margin: 0px auto;
                    display: block;
                }
            }
        }
    }
}