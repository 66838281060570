.helpcase {
    background-color: $beige;
    padding: rem-calc(80 0);
    position: relative;
    .inner-helpcase {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: rem-calc(130);
        align-items: center;
        .left {
            h2 {
                font-family: $medium;
                font-size: rem-calc(48);
                line-height: rem-calc(58);
                font-weight: lighter;
                text-align: left;
                margin: 0;
                padding-bottom: rem-calc(25);
            }
            p {
                font-family: $regular;
                font-weight: lighter;
                color: $gray;
                font-size: rem-calc(20);
                line-height: rem-calc(28);
                padding-bottom: rem-calc(35);
                max-width: rem-calc(493);
                margin: 0;
            }
            ul {
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                li {
                    margin-bottom: rem-calc(15);
                    font-size: rem-calc(18);
                    font-weight: lighter;
                    font-family: $medium;
                    display: flex;
                    align-items: center;
                    svg {
                        margin-right: rem-calc(8);
                        fill: $red;
                    }
                }
            }
        }
        .right {
            img {
                max-width: 100%;
                object-fit: contain;
                height: auto;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .helpcase {
        background-color: $beige;
        padding: rem-calc(45 0);
        position: relative;
        .inner-helpcase {
            display: grid;
            grid-template-columns: 1fr;
            column-gap: rem-calc(0);
            align-items: center;
            .left {
                h2 {
                    font-family: $medium;
                    font-size: rem-calc(32);
                    line-height: rem-calc(42);
                    font-weight: lighter;
                    text-align: left;
                    margin: 0;
                    padding-bottom: rem-calc(15);
                }
                p {
                    font-family: $regular;
                    font-weight: lighter;
                    color: $gray;
                    font-size: rem-calc(18);
                    line-height: rem-calc(26);
                    padding-bottom: rem-calc(25);
                    max-width: rem-calc(493);
                    margin: 0;
                }
                ul {
                    margin: 0;
                    padding: rem-calc(0 0 25);
                    list-style-type: none;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    li {
                        margin-bottom: rem-calc(10);
                        font-size: rem-calc(16);
                        font-weight: lighter;
                        font-family: $medium;
                        display: flex;
                        align-items: center;
                        svg {
                            margin-right: rem-calc(5);
                            fill: $red;
                        }
                    }
                }
            }
            .right {
                img {
                    max-width: 100%;
                    object-fit: contain;
                    height: auto;
                }
            }
        }
    }
}