.cta {
    padding: rem-calc(80 0);
    position: relative;
    &.no-image {
        .inner-cta {
            background-color: $black;
            padding: rem-calc(20 50 50);
            h3 {
                color: white;
            }
            p {
                color: white;
            }
        }
    }
    .inner-cta {
        background-color: $beige;
        padding: rem-calc(0 50 50);
        border-radius: rem-calc(12);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        img {
            width: rem-calc(507);
            object-fit: contain;
            height: auto;
            margin-top: rem-calc(-30);
        }
        h3 {
            font-family: $medium;
            font-size: rem-calc(32);
            line-height: rem-calc(42);
            padding: rem-calc(35 0 15);
            margin: 0;
        }
        .MuiButton-root {
            border-radius: rem-calc(25);
            padding: rem-calc(12 25);
            font-family: $medium;
            letter-spacing: rem-calc(0.5);
            font-size: rem-calc(14);
            background-color: $red;
            svg {
                margin-right: rem-calc(10);
            }
        }
        p {
            text-align: center;
            font-family: $regular;
            font-weight: lighter;
            color: $gray;
            font-size: rem-calc(16);
            line-height: rem-calc(24);
            max-width: rem-calc(462);
            margin: 0px auto;
            padding-bottom: rem-calc(25);
        }   
    }
}

@media screen and (max-width: $mobile) {
    .cta {
        padding: rem-calc(95 0 45);
        position: relative;
        .inner-cta {
            background-color: $beige;
            padding: rem-calc(0 25 35);
            border-radius: rem-calc(12);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            img {
                width: rem-calc(285);
                object-fit: contain;
                height: auto;
                margin-top: rem-calc(-95);
            }
            h3 {
                font-family: $medium;
                font-size: rem-calc(22);
                line-height: rem-calc(30);
                padding: rem-calc(25 0 10);
                margin: 0;
            }
            .MuiButton-root {
                border-radius: rem-calc(25);
                padding: rem-calc(12 25);
                font-family: $medium;
                letter-spacing: rem-calc(0.5);
                font-size: rem-calc(14);
                background-color: $red;
                svg {
                    margin-right: rem-calc(10);
                }
            }
            p {
                text-align: center;
                font-family: $regular;
                font-weight: lighter;
                color: $gray;
                font-size: rem-calc(16);
                line-height: rem-calc(24);
                max-width: rem-calc(462);
                margin: 0px auto;
                padding-bottom: rem-calc(25);
            }   
        }
    }
    
}