.index-patient {
    padding: rem-calc(80 0);
    position: relative;
    h1 {
        text-align: center;
        font-family: $medium;
        font-size: rem-calc(32);
        line-height: rem-calc(42);
        padding: rem-calc(0 0 50);
        margin: 0;
    }
    .btn-holder {
       display: flex;
       justify-content: center;
    }
}

.index-image {
    padding: rem-calc(45 0 0);
    max-width: rem-calc(650);
    margin: 0px auto;
    svg {
        max-width: 100%;
        height: auto;
        object-fit: contain;
    }
}

.index-doctor {
    padding: rem-calc(80 0);
    position: relative;
    h1 {
        text-align: center;
        font-family: $medium;
        font-size: rem-calc(32);
        line-height: rem-calc(42);
        padding: rem-calc(0 0 50);
        margin: 0;
    }
    .btn-holder {
       display: flex;
       justify-content: center;
    }
}

.intro {
    background-color: $beige;
    padding: rem-calc(80 0);
    position: relative;
    height: rem-calc(400);
    h1 {
        margin: 0;
        font-size: rem-calc(48);
        line-height: rem-calc(62);
        color: $text;
        font-family: $medium;
        font-weight: lighter;
        text-align: center;
        max-width: rem-calc(700);
        margin: 0px auto;
    }
    img {
        position: absolute;
        left: 0;
        max-width: 100%;
        bottom: rem-calc(-5);
    }
    .btn-holder {
        display: flex;
        justify-content: center;
    }
}

.dialog-holder {
    max-width: rem-calc(600);
    margin: 0px auto;
    padding: rem-calc(50 0);
    h2 {
        margin: 0;
        font-size: rem-calc(32);
        line-height: rem-calc(43);
        color: $text;
        font-family: $regular;
        font-weight: lighter;
        text-align: center;
        padding-bottom: rem-calc(25);
    }
    p {
        margin: 0px auto;
        font-size: rem-calc(18);
        line-height: rem-calc(26);
        color: $text;
        font-family: $regular;
        font-weight: lighter;
        text-align: center;
        padding-bottom: rem-calc(50);
        max-width: rem-calc(485);
        font-style: italic;
        color: #787F82;
    }
    .border-r {
        border-bottom: rem-calc(1) solid #DEDEDE;
        margin-bottom: rem-calc(50);
    }
    .inner-dialog-holder {
        .upper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            form {
                display: flex;
                justify-content: center;
                flex-flow: row wrap;
            }
        }
        .images-from-patient {
            margin: rem-calc(8 8 27 8);
            h4 {
                font-family: $regular;
                font-weight: lighter;
                padding-bottom: rem-calc(15);
            }
            .image-holder {
                display: flex;
                flex-flow: row wrap;
                img {
                    object-fit: cover;
                    border: rem-calc(1) solid #c4c4c4;
                    width: rem-calc(130);
                    height: rem-calc(130);
                    margin: rem-calc(0 8 8 0);
                    border-radius: rem-calc(4);
                }
            }
        }
        .patient-information-box {
            margin: rem-calc(8);
        }
        .under {
            margin: rem-calc(8);
            display: grid;
            grid-template-columns: 1fr;
            .file-upload {
                position: relative;
                height: rem-calc(150);
                width: 100%;
                border-radius: rem-calc(12);
                border: rem-calc(1) dashed #c4c4c4;

                .overlay {
                    width: 100%;
                    height: rem-calc(150);
                    opacity: 1;
                    position: absolute;
                    z-index: 1;
                    .holder {
                        display: flex;
                        height: 100%;
                        align-items: center;
                        padding-left: rem-calc(28);
                        h3 {
                            padding-left: rem-calc(17);
                            color: #9E9E9E;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                        
                    }
                }
                input {
                    width: 100%;
                    height: rem-calc(150);
                    opacity: 0;
                    position: absolute;
                    z-index: 99;
                    &:hover {
                        cursor: pointer;
                        
                    }
                }
            }
            .upload-img-container {
                display: flex;
                width: 100% !important;
                flex-flow: row wrap;
                img {
                    width: rem-calc(130);
                    height: rem-calc(130);
                    margin-right: rem-calc(10);
                    padding: rem-calc(10);
                    margin-bottom: rem-calc(10);
                    border-radius: rem-calc(4);
                    object-fit: contain;
                    border: rem-calc(1) solid #9E9E9E;
                }
            }
        }
        .btn-holder {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}


.aktiv-behandler-wrapper {
    position: fixed;
    background-color: transparentize($color: $black, $amount: 0.15);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    .inner {
        background: white;
        max-width: rem-calc(600);
        width: 100%;
        border-radius: rem-calc(12);
        padding: rem-calc(30 25);
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
        svg {
            fill: $red;
            font-size: rem-calc(52);
        }
        h2 {
            margin: 0;
            font-size: rem-calc(32);
            line-height: rem-calc(43);
            color: $text;
            font-family: $medium;
            font-weight: lighter;
            text-align: left;
            padding-top: rem-calc(15);
            padding-bottom: rem-calc(15);
        }
        p {
            font-size: rem-calc(18);
            color: $text;
            margin: 0;
            padding-bottom: rem-calc(25);
            line-height: rem-calc(26);
            max-width: rem-calc(550);
        }
        .MuiButton-root {
            border-radius: rem-calc(25);
            padding: rem-calc(12 25);
            font-family: $medium;
            letter-spacing: rem-calc(0.5);
            font-size: rem-calc(14);
            background-color: $red;
        }
    }
}

.support-tab {
    h2 {
        margin: 0;
        font-size: rem-calc(32);
        line-height: rem-calc(43);
        color: $text;
        font-family: $regular;
        font-weight: lighter;
        text-align: left;
        padding-bottom: rem-calc(50);
    }
    p {
        font-size: rem-calc(20);
    } 
}
.table-behandlinger {
    position: relative;
    z-index: 90;
    h2 {
        margin: 0;
        font-size: rem-calc(32);
        line-height: rem-calc(43);
        color: $text;
        font-family: $regular;
        font-weight: lighter;
        text-align: left;
        padding-bottom: rem-calc(50);
    }
    .btn-holder {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: $mobile) {
    .table-behandlinger {
        position: relative;
        z-index: 90;
        h2 {
            margin: 0;
            font-size: rem-calc(26);
            line-height: rem-calc(34);
            color: $text;
            font-family: $regular;
            font-weight: lighter;
            text-align: left;
            padding-bottom: rem-calc(25);
        }
    }
    .intro {
        background-color: $beige;
        padding: rem-calc(45 0);
        position: relative;
        height: rem-calc(290);
        h1 {
            margin: 0;
            font-size: rem-calc(32);
            line-height: rem-calc(43);
            color: $text;
            font-family: $medium;
            font-weight: lighter;
            text-align: center;
        }
        img {
            position: absolute;
            left: 0;
            max-width: 100%;
            bottom: rem-calc(-5);
        }
        .btn-holder {
            display: flex;
            justify-content: center;
        }
    }
    
    .index-image {
        padding: rem-calc(45 0 0);
        max-width: 100%;
        margin: 0px auto;
        svg {
            max-width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    .heisannboks {
        .MuiPaper-root {
            margin: rem-calc(20) !important;
            max-height: calc(100% - 100px) !important;
            max-width: 900px !important;
            width: calc(100% - 40px) !important;
        }
    }

    .dialog-holder {
        max-width: 100%;
        margin: 0px auto;
        padding: rem-calc(25 20);
        h2 {
            margin: 0;
            font-size: rem-calc(26);
            line-height: rem-calc(34);
            color: $text;
            font-family: $regular;
            font-weight: lighter;
            text-align: center;
            padding-bottom: rem-calc(15);
        }
        p {
            margin: 0px auto;
            font-size: rem-calc(14);
            line-height: rem-calc(22);
            color: $text;
            font-family: $regular;
            font-weight: lighter;
            text-align: center;
            padding-bottom: rem-calc(25);
            max-width: rem-calc(250);
            font-style: italic;
            color: #787F82;
        }

        .border-r {
            border-bottom: rem-calc(1) solid #DEDEDE;
            margin-bottom: rem-calc(25);
        }

        .inner-dialog-holder {
            .upper {
                display: grid;
                grid-template-columns: 1fr;
                .MuiFormControl-root {
                    width: 100%;
                    margin: rem-calc(0 0 20);
                }
            }
            .under {
                margin: 0;
                display: grid;
                grid-template-columns: 1fr;
                .file-upload {
                    position: relative;
                    height: rem-calc(130);
                    width: 100%;
                    border-radius: rem-calc(12);
                    border: rem-calc(1) dashed $gray;
                    margin-bottom: rem-calc(25);
                    .overlay {
                        width: 100%;
                        height: rem-calc(130);
                        opacity: 1;
                        position: absolute;
                        z-index: 1;
                        .holder {
                            display: flex;
                            height: 100%;
                            align-items: center;
                            padding-left: rem-calc(15);
                            h3 {
                                padding-left: rem-calc(15);
                                padding-right: rem-calc(15);
                                color: #9E9E9E;
                                font-size: rem-calc(14);
                            }
                            button {
                                height: 49px !important;
                                width: 51px !important;
                            }
                        }
                        &:hover {
                            cursor: pointer;
                            
                        }
                    }
                    input {
                        width: 100%;
                        height: rem-calc(130);
                        opacity: 0;
                        position: absolute;
                        z-index: 99;
                        &:hover {
                            cursor: pointer;
                            
                        }
                    }
                }
                .upload-img-container {
                    display: flex;
                    width: 100% !important;
                    flex-flow: row wrap;
                    margin-bottom: rem-calc(25);
                    img {
                        width: rem-calc(90);
                        height: rem-calc(90);
                        margin-right: rem-calc(8);
                        padding: rem-calc(8);
                        margin-bottom: rem-calc(8);
                        border-radius: rem-calc(4);
                        object-fit: contain;
                        border: rem-calc(1) solid #9E9E9E;
                    }
                }
            }
            .btn-holder {
                display: flex;
                align-items: center;
                justify-content: center;
                button {
                    margin-top: rem-calc(25);
                }
            }
            .images-from-patient {
                margin: rem-calc(0 0 17 0);
                h4 {
                    font-family: $regular;
                    font-weight: lighter;
                    padding-bottom: rem-calc(10);
                }
                .image-holder {
                    display: flex;
                    flex-flow: row wrap;
                    img {
                        object-fit: cover;
                        border: rem-calc(1) solid #c4c4c4;
                        width: rem-calc(90);
                        height: rem-calc(90);
                        margin: rem-calc(0 8 8 0);
                        border-radius: rem-calc(4);
                    }
                }
            }
            .patient-information-box {
                margin: rem-calc(0);
            }
        }
    }
    .aktiv-behandler-wrapper {
        position: fixed;
        background-color: transparentize($color: $black, $amount: 0.15);
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        .inner {
            background: white;
            max-width: rem-calc(600);
            width: calc(100vw - 30px);
            border-radius: rem-calc(12);
            padding: rem-calc(25 20);
            position: absolute;
            left: 50%;
            top: 40%;
            transform: translate(-50%, -50%);
            svg {
                fill: $red;
                font-size: rem-calc(40);
            }
            h2 {
                margin: 0;
                font-size: rem-calc(26);
                line-height: rem-calc(34);
                color: $text;
                font-family: $medium;
                font-weight: lighter;
                text-align: left;
                padding-top: rem-calc(10);
                padding-bottom: rem-calc(10);
            }
            p {
                font-size: rem-calc(16);
                color: $text;
                margin: 0;
                padding-bottom: rem-calc(15);
                line-height: rem-calc(24);
                max-width: 100%;
            }
            .MuiButton-root {
                border-radius: rem-calc(25);
                padding: rem-calc(8 18);
                font-family: $medium;
                letter-spacing: 0;
                font-size: rem-calc(13);
                background-color: $red;
            }
        }
    }    
}