.accept-doctor {
    padding: rem-calc(80 0);
    position: relative;
    svg {
        display: block;
        margin: 0px auto;
        max-width: rem-calc(320);
        padding-bottom: rem-calc(50);
        height: auto;
        width: 100%;
    }
    h1 {
        font-family: $medium;
        font-size: rem-calc(48);
        line-height: rem-calc(58);
        font-weight: lighter;
        text-align: center;
        margin: 0;
        padding-bottom: rem-calc(25);
    }
    p {
        text-align: center;
        font-family: $regular;
        font-weight: lighter;
        color: $gray;
        font-size: rem-calc(20);
        line-height: rem-calc(28);
        max-width: rem-calc(732);
        margin: 0px auto;
        padding-bottom: rem-calc(50);
    }
}

@media screen and (max-width: $mobile) {
    .accept-doctor {
        padding: rem-calc(45 0);
        position: relative;
        svg {
            display: block;
            margin: 0px auto;
            max-width: rem-calc(200);
            padding-bottom: rem-calc(35);
            height: auto;
            width: 100%;
        }
        h1 {
            font-family: $medium;
            font-size: rem-calc(32);
            line-height: rem-calc(38);
            font-weight: lighter;
            text-align: center;
            margin: 0;
            padding-bottom: rem-calc(15);
        }
        p {
            text-align: center;
            font-family: $regular;
            font-weight: lighter;
            color: $gray;
            font-size: rem-calc(18);
            line-height: rem-calc(24);
            max-width: 100%;
            margin: 0px auto;
            padding-bottom: rem-calc(0);
        }
    }
}