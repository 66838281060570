.pills {
    padding: rem-calc(80 0);
    position: relative;
    .inner-pills {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: rem-calc(30);
        row-gap: rem-calc(35);
        text-align: center;
        .single-pill {
            .icon {
                display: inline-flex;
                height: rem-calc(60);
                width: rem-calc(60);
                justify-content: center;
                align-items: center;
                background: transparentize($color: $red, $amount: 0.9);
                border-radius: 50%;
                svg {
                    fill: $red;
                }
            }
            h4 {
                font-family: $medium;
                font-size: rem-calc(20);
                font-weight: lighter;
                padding: rem-calc(25 0 0);
            }
            p {
                text-align: center;
                font-family: $regular;
                font-weight: lighter;
                color: $gray;
                font-size: rem-calc(16);
                line-height: rem-calc(24);
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .pills {
        padding: rem-calc(45 0);
        position: relative;
        .inner-pills {
            display: grid;
            grid-template-columns: 1fr;
            column-gap: rem-calc(0);
            row-gap: rem-calc(25);
            text-align: center;
            .single-pill {
                .icon {
                    display: inline-flex;
                    height: rem-calc(55);
                    width: rem-calc(55);
                    justify-content: center;
                    align-items: center;
                    background: transparentize($color: $red, $amount: 0.9);
                    border-radius: 50%;
                    svg {
                        fill: $red;
                    }
                }
                h4 {
                    font-family: $medium;
                    font-size: rem-calc(18);
                    font-weight: lighter;
                    padding: rem-calc(15 0 0);
                }
                p {
                    text-align: center;
                    font-family: $regular;
                    font-weight: lighter;
                    color: $gray;
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                }
            }
        }
    }
    
}