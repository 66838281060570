$rem-base: 16px;

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@function convert-to-rem($value, $base-value: $rem-base)  {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);
  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }
  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

$red: #DD0A01;
$beige: #FAEFE6;
$black: #2A2D2E;
$gray: #788185;
$text: #2C3748;

$bold: 'JakartaB', sans-serif;
$regular: 'JakartaR', sans-serif;
$medium: 'JakartaM', sans-serif;

$bs: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
$bs-light: 0px 3px 6px rgba(0,0,0, 0.16);

$mobile: rem-calc(1024);