.hangup {
    padding: rem-calc(80 0 50);
    display: flex;
    align-items: center;
    justify-content: center;
    .svg-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem-calc(35);
        svg {
            width: rem-calc(370);
            height: auto;
            object-fit: contain;
        }
    }
    h2 {
        font-family: $medium;
        font-size: rem-calc(32);
        line-height: rem-calc(42);
        padding: rem-calc(0 0 35);
        margin: 0;
        text-align: center;
    }
    .MuiButton-root {
        border-radius: rem-calc(25);
        padding: rem-calc(12 25);
        font-family: $medium;
        letter-spacing: rem-calc(0.5);
        font-size: rem-calc(14);
        background-color: $red;
        svg {
            margin-right: rem-calc(10);
        }
    }
    .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}