.cards {
    padding: rem-calc(80 0);
    position: relative;
    h2 {
        font-family: $medium;
        font-size: rem-calc(48);
        line-height: rem-calc(58);
        font-weight: lighter;
        text-align: center;
        margin: 0;
        padding-bottom: rem-calc(25);
    }
    p {
        text-align: center;
        font-family: $regular;
        font-weight: lighter;
        color: $gray;
        font-size: rem-calc(20);
        line-height: rem-calc(28);
        max-width: rem-calc(532);
        margin: 0px auto;
        padding-bottom: rem-calc(50);
    }
    .inner-cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: rem-calc(30);
        row-gap: rem-calc(35);
        .single-card {
            box-shadow: $bs-light;
            padding: rem-calc(25 20);
            border-radius: rem-calc(12);
            .icon {
                display: inline-flex;
                height: rem-calc(50);
                width: rem-calc(50);
                justify-content: center;
                align-items: center;
                background: $red;
                border-radius: 50%;
                margin-bottom: rem-calc(15);
                svg {
                    fill: white;
                }
            }
            h3 {
                font-family: $medium;
                font-size: rem-calc(20);
                line-height: rem-calc(28);
                font-weight: lighter;
                text-align: left;
                margin: 0;
                padding-bottom: rem-calc(15);
            }
            p {
                text-align: left;
                font-family: $regular;
                font-weight: lighter;
                color: $gray;
                font-size: rem-calc(16);
                line-height: rem-calc(24);
                max-width: 100%;
                margin: 0;
                padding-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: $mobile) {
    .cards {
        padding: rem-calc(45 0);
        position: relative;
        h2 {
            font-family: $medium;
            font-size: rem-calc(32);
            line-height: rem-calc(42);
            font-weight: lighter;
            text-align: center;
            margin: 0;
            padding-bottom: rem-calc(15);
        }
        p {
            text-align: center;
            font-family: $regular;
            font-weight: lighter;
            color: $gray;
            font-size: rem-calc(18);
            line-height: rem-calc(26);
            max-width: rem-calc(532);
            margin: 0px auto;
            padding-bottom: rem-calc(25);
        }
        .inner-cards {
            display: grid;
            grid-template-columns: 1fr;
            column-gap: rem-calc(0);
            row-gap: rem-calc(25);
            .single-card {
                box-shadow: $bs-light;
                padding: rem-calc(20 20);
                border-radius: rem-calc(12);
                text-align: center;
                .icon {
                    display: inline-flex;
                    height: rem-calc(45);
                    width: rem-calc(45);
                    justify-content: center;
                    align-items: center;
                    background: $red;
                    border-radius: 50%;
                    margin-bottom: rem-calc(10);
                    svg {
                        fill: white;
                    }
                }
                h3 {
                    font-family: $medium;
                    font-size: rem-calc(18);
                    line-height: rem-calc(26);
                    font-weight: lighter;
                    text-align: left;
                    margin: 0;
                    padding-bottom: rem-calc(5);
                    text-align: center;
                }
                p {
                    text-align: left;
                    font-family: $regular;
                    font-weight: lighter;
                    color: $gray;
                    font-size: rem-calc(16);
                    line-height: rem-calc(24);
                    max-width: 100%;
                    margin: 0;
                    padding-bottom: 0;
                    text-align: center;
                }
            }
        }
    }
}