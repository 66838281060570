.four-o-four {
    padding: rem-calc(80 0);
    h1 {
        font-family: $medium;
        font-size: rem-calc(32);
        line-height: rem-calc(42);
        padding: rem-calc(0 0 50);
        margin: 0;
        text-align: center;
    }
    .logo-image {
        svg {
            max-width: rem-calc(300);
            width: 100%;
            margin: 0px auto;
            display: block;
            height: auto;
        }
    }
    .button-wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: rem-calc(50);
    }
}

@media screen and (max-width: $mobile) {
    .four-o-four {
        padding: rem-calc(45 0);
        h1 {
            font-family: $medium;
            font-size: rem-calc(26);
            line-height: rem-calc(34);
            padding: rem-calc(0 0 25);
            margin: 0;
            text-align: center;
        }
        .logo-image {
            svg {
                max-width: rem-calc(250);
                width: 100%;
                margin: 0px auto;
                display: block;
                height: auto;
            }
        }
        .button-wrapper {
            display: flex;
            justify-content: center;
            margin-bottom: rem-calc(25);
        }
    }
}